// Generated by Framer (552ec80)

import { addFonts, cx, CycleVariantState, Link, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-NZA6L"

const variantClassNames = {YzG9o3ms7: "framer-v-1yb5abu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "YzG9o3ms7", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://www.linkedin.com/in/omshewale007/"} nodeId={"YzG9o3ms7"}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1yb5abu", className, classNames)} framer-1r6rtvy`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"YzG9o3ms7"} ref={ref ?? ref1} style={{backgroundColor: "rgb(244, 237, 255)", ...style}}><SVG className={"framer-vcyh3y"} data-framer-name={"Graphic"} layout={"position"} layoutDependency={layoutDependency} layoutId={"J1pz79Qe4"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 37 37\"><path d=\"M 29.6 4.933 L 7.4 4.933 C 6.038 4.933 4.933 6.038 4.933 7.4 L 4.933 29.6 C 4.933 30.962 6.038 32.067 7.4 32.067 L 29.6 32.067 C 30.962 32.067 32.067 30.962 32.067 29.6 L 32.067 7.4 C 32.067 6.038 30.962 4.933 29.6 4.933 Z M 13.51 27.133 L 9.872 27.133 L 9.872 15.427 L 13.51 15.427 Z M 11.654 13.753 C 10.482 13.753 9.532 12.803 9.532 11.632 C 9.532 10.46 10.482 9.51 11.654 9.51 C 12.825 9.51 13.775 10.46 13.775 11.632 C 13.775 12.803 12.825 13.753 11.654 13.753 Z M 27.138 27.133 L 23.502 27.133 L 23.502 21.44 C 23.502 20.082 23.478 18.336 21.612 18.336 C 19.719 18.336 19.427 19.815 19.427 21.342 L 19.427 27.133 L 15.792 27.133 L 15.792 15.427 L 19.282 15.427 L 19.282 17.026 L 19.331 17.026 C 19.817 16.106 21.004 15.135 22.774 15.135 C 26.457 15.135 27.138 17.56 27.138 20.713 Z\" fill=\"rgb(43, 122, 207)\"></path></svg>"} svgContentId={9934297699} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-NZA6L.framer-1r6rtvy, .framer-NZA6L .framer-1r6rtvy { display: block; }", ".framer-NZA6L.framer-1yb5abu { height: 38px; overflow: visible; position: relative; text-decoration: none; width: 38px; }", ".framer-NZA6L .framer-vcyh3y { flex: none; height: 37px; left: calc(50.00000000000002% - 37px / 2); position: absolute; top: calc(50.00000000000002% - 37px / 2); width: 37px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 38
 * @framerIntrinsicWidth 38
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerO2VmHN3o3: React.ComponentType<Props> = withCSS(Component, css, "framer-NZA6L") as typeof Component;
export default FramerO2VmHN3o3;

FramerO2VmHN3o3.displayName = "Graphicln";

FramerO2VmHN3o3.defaultProps = {height: 38, width: 38};

addFonts(FramerO2VmHN3o3, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})